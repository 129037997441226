.ant-advanced-container {
  margin-bottom: 10px;
  text-align: right;
  //padding: 0 10px;
}

.ant-advanced-search-form {
  float: right;
  width: 100%;
  padding: 24px;
  margin: 20px 0 10px 0;
  border-radius: 6px;
  box-shadow: 0px 0px 20px 0px rgba(222, 222, 233, 0.75);
  text-align: left;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.right {
  text-align: right;
}


.showStyle {
  display: block;
}

.hideStyle {
  display: none;
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;