@import "~framework/assets/css/config.less";

.upload {
  position:fixed;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.24);
  width: 200px;
  // height: 100px;
  z-index: 99999;
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;