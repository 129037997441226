.uploadWrap {
  display: inline-block;
  vertical-align: top;
}

.previewImage {
  width: 100%;
}

.elUploadTip {
  font-size: 12px;
  color: #8391a5;
  margin-top: 7px;
}

:global {
  .image-uploader-wrap {
    ul.el-upload-list.el-upload-list--picture-card {
      &+.el-upload.el-upload--picture-card {
        display: none;
      }
    }
  }
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;