.button {
  display: inline-block;
  vertical-align: top;
  min-width: 100px;
  margin-right: 10px;
  height: 36px;
  line-height: 36px;
  font-size: 0;
  :global {
    .icon-import, .icon-export {
      position: relative;
      top: 1px;
    }
    .sc-icon-setting2 {
      position: relative;
      right: 5px;
    }
  }
}

.icon {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  line-height: 16px;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 8px;
}

.text {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  color: #ffffff;
}
.disabled {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  color: #808080;
}
@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;