.messageView {
  text-align: center;
  padding: 50px;
}

.messageImage {

}

.tipText {
  font-size: 14px;
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;