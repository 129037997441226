.scanLoginWrap {
  width: 700px;
  padding-top: 130px;
  .iframeWrap {
    width: 240px;
    height: 340px;
    margin: 0 auto;
    border: none;
    :global {
      .vc-text {
        font-size: 22px;
        color: #3A405B;
      }
    }
  }
  
  .tip {
    color: #3A405B;
    text-align: center;
  }
}
@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;