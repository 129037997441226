.smsVerifyInput {
  display: block;
  overflow: hidden;
}

.input {
  float: left;
  width: 56.21%;
}

.verifyBtn {
  float: right;
  width: 41.89%;
  text-align: right;
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;