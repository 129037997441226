@import 'config.less';
@import "modules/margin";
@import "modules/icon";

body {
  min-width: @min-width;
  //user-select: none;
}

// 阿里SB
.christmas.ant-btn-primary:before {
  display: none !important;
}

//
.ant-form,
.ant-modal-body {
  .ant-row {
    margin-bottom: 20px;
    overflow: hidden;
    &:before,
    &:after {
      display: initial;
    }
  }
  .ant-form-item {
    margin-bottom: 15px !important;
    .ant-form-explain {
      margin-top: 5px;
      font-size: 13px;
      line-height: 13px;
    }
  }
}

.ant-form-item-label label {
  color: #85888c !important;
}

.ant-select, .ant-cascader-picker {
  width: 100%;
}

[class^="sc-icon-"], [class*=" sc-icon-"] {
  font-family: "scIconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-spin-container {
  overflow: visible !important;
}

.global-spin {
  width: 100%;
  margin: 40px 0 !important;
}

.ant-input {
  background-color: #ffffff !important;
  &.ant-input-lg {
    height: 38px;
    font-size: 14px;
  }
}

.ant-input.ant-input-disabled {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

button {
  &.ant-btn-sm {
    height: 24px;
  }
  &.middle {
    height: 32px;
  }
  &.default {
    height: 36px;
  }
  &.ant-btn-lg {
    height: 40px;
    font-size: 14px;
  }
}

//
a {
  &:focus {
    text-decoration: none;
  }
}

//
/*隐藏文本框叉子*/
input::-ms-clear {
  display: none;
}

/*隐藏密码框小眼睛*/
input::-ms-reveal {
  display: none;
}

//
.fl-l {
  float: left;
}

.fl-r {
  float: right;
}

.mr-10 {
  margin-right: 10px;
}

.disn {
  display: none;
}

.dark-link {
  color: @text-primary-color;
  &:hover,
  &:active,
  &:focus {
    color: @text-primary-color;
  }
}

//
.ie11 {
  .el-table__fixed-right {
    right: 0 !important;
    bottom: 0 !important;
  }
}

//
.cjm-form {
  .el-form-item {
    &.horizontal {
      width: 49%;
      display: inline-block;
      vertical-align: top;
      &:nth-of-type(2n+1) {
        margin-right: 2%;
      }
    }
  }
}
