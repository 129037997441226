@import '~framework/assets/css/config.less';

.stepGroup {
  position: relative;
  display: block;
  white-space: nowrap;
  height: 62px;
  text-align: center;
}

.stepItem {
  display: inline-block;
  vertical-align: top;
  .inner {
    @roundSize: 33px;
    @roundBorderWidth: 2px;
    @roundWidth: @roundSize - @roundBorderWidth*2;
    @roundHeight: @roundWidth;
    position: relative;
    width: 51px;
    display: inline-block;
    vertical-align: top;
    .round {
      width: @roundWidth;
      height: @roundHeight;
      line-height: @roundHeight;
      border-radius: 50%;
      background-color: #ffffff;
      border: @roundBorderWidth solid @border-color;
      font-size: 16px;
      color: @border-color;
      font-family: 'Microsoft YaHei';
      margin: 0 auto;
      span {
        display: inline-block;
        vertical-align: top;
        line-height: 16px;
        margin-top: 5px;
      }
    }
    .label {
      position: absolute;
      width: 300px;
      left: 50%;
      top: 100%;
      margin-left: -150px;
      margin-top: 10px;
    }
  }
  .connectLine {
    display: inline-block;
    vertical-align: top;
    height: 1px;
    background-color: @border-color;
    margin-top: 15px;
  }
  &.normal {
  }
  &.pass {
    .round {
      border-color: @primary-color;
      color: @primary-color;
      .checkIcon {
        vertical-align: top;
        margin-top: 5px;
      }
    }
    .connectLine {
      background-color: @primary-color;
    }
  }
  &.current {
    .round {
      background-color: @primary-color;
      border-color: @primary-color;
      color: #ffffff;
    }
  }
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;