.basicInfo {
  display: flex;
  flex-wrap: wrap;
  .item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    text-align: center;
    // background-image: url("userCenter/assets/images/org-bg.png");
    // background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 100% auto;
    width: 380px;
    height: 260px;
    margin-right: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 1px 16px 0px rgba(51, 91, 255, 0.1);
    filter: gray;
    opacity: 0.7;
    border-radius: 20px;
    padding: 16px;
    img {
      height: 100px;
    }
  }
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;