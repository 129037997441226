.rowText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:global {
  .cjm-table {
    @border-color: #e6e6e6;
    box-sizing: border-box;
    border-right: 1px solid @border-color;

    td, th {
      height: 55px;
    }

    td, th, .el-table {
      border-color: @border-color;
      background-color: #ffffff;
    }

    .el-table__row, .el-table__header {
      .cell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .el-table__body {
      // 使表格兼容safari，不错位
      width: 100%;
      table-layout: fixed !important;

      td {
        border-right: none;
        border-left: none;
      }
    }

    .el-table__header {
      th {
        border-right: none;
        border-left: none;
        background-color: #fafafa;
      }
    }

    .el-table__footer-wrapper thead div,
    .el-table__header-wrapper thead div,
    .el-table__fixed-header-wrapper thead div {
      background-color: #fafafa;
    }

    .el-table__row.hover-row {
      td {
        background-color: #e6f7ff;
      }
    }

    .normal-header-cell {
      display: inline-block;

      .header-right-border {
        position: absolute;
        display: block;
        width: 1px;
        height: 20px;
        right: 0;
        top: 50%;
        margin-top: -10px;
        background-color: @border-color;
      }
    }
  }
}

.tooltipCell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;