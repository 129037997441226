@import "~framework/assets/css/config.less";

.searchBox {
  position: relative;
  width: 298px;
  border: 1px solid @border-color;
  display: inline-block;
  vertical-align: top;
  & + .searchBox {
    border-left: none;
  }
}

.baseSearchWrap {
  position: absolute;
  width: 217px;
  height: 64px;
  left: 20px;
  top: 0;
  background-color: #ffffff;
  padding-top: 16px;
}

.searchInput {
  width: 100% !important;
}

.listWrap, .scrollbars {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.listGroup {
  padding: 64px 0 20px 0;
}

.option {
  position: relative;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  padding: 0 50px 0 20px;
  cursor: pointer;
  &:hover, &.current {
    background-color: @light-color-3;
  }
  &.current {
    cursor: default;
  }
  .optionName {
    font-size: 12px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .rightArrow {
    position: absolute;
    right: 20px;
    top: 11px;
    color: #c2c2c2;
    font-size: 10px;
  }
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;