.userLayout {
  // background: url(../../assets/images/logouBg.jpg) 0% 0% / cover no-repeat fixed;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    margin: 0 auto;
    margin-left: 362px;
    img {
      margin-top: -20px;
      width: 50px;
      height: 50px;
      vertical-align: middle;
      border-style: none;
      display: inline-block;
    }
    h1 {
      position: relative;
      margin: 0 auto;
      padding-top: 90px;
      margin-bottom: 40px;
      width: 1180px;
      display: inline-block;
    }
  }
  .login {
    position: relative;
    // left: 30%;
    // top: 10%;
    box-sizing: border-box;
    width: 1180px;
    height: 600px;
    margin: 0 auto;
    -moz-border-radius: 10px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #ffffff;
    z-index: 100;
    .loginLeft {
      width: 630px;
      display: inline-block;
      float: left;
      .loginpanel {
        height: 360px;

        .logincontainer {
          width: 100%;
          box-sizing: border-box;
          width: 100%;
          margin: 20px 0 0;
          text-align: center;
          display: flex;
          justify-content: center;
        }
    
        .loginpassword {
          position: relative;
          width: 100%;
          box-sizing: border-box;
          width: 100%;
          margin: 20px 0 0;
          text-align: center;
        }
        .renjiyanzhengma{
          width: 60%;
          height: 38px;
          margin-left: 125px;
          margin-top: 20px
        }
      }
      h2 {
        text-align: center;
        padding-top: 90px;
        padding-bottom: 20px;
      }
    }
    .loginRight {
      float: right;
      width: 450px;
      height: 600px;
      display: inline-block;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// .userLoginFrame {
//   position: relative;
//   width: 500px;
//   height: 500px;
//   margin: 0 auto;
//   top: 100px;
//   display: block;
//   border: none;
// }

.login .input {
  border: 1px solid #d9d9d9;
  background-color: #ffffff !important;
  border-radius: 4px;
  display: inline-block;
  box-sizing: border-box;
  width: 60%;
  height: 46px;
  padding-left: 10px;
  margin: 0 auto;
  font-size: 14px;
  outline: none;
  color: #76838f;
}

.login .loginvalidation {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.login .validationinput {
  position: absolute;
  width: 250px;
  left: 0px;
}

.login img.validationimg {
  position: absolute;
  cursor: pointer;
  width: 125px;
  height: 45px;
  right: 0px;
}

.login .input:hover {
  border-color: #5cb3ff;
  border-right-width: 1px !important;
}

.login .btnlogin {
  background-color: #62a8ea;
  border: none;
  width: 60%;
  height: 45px;
  line-height: 45px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  text-align: center;
}

.login .btnlogin:hover {
  opacity: 0.9;
}

.eye {
  position: absolute;
  right: 143px;
  top: 10px;
  line-height: 1.5;
  width: 22px;
  height: 22px;
  .eyeimg {
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../../assets/images/eye.png");
  }
  .invisible {
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../../assets/images/heiEye.png");
  }
}
.forget {
  margin-left: 444px;
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;