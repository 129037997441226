.percentCommon() {
  display: inline-block;
  vertical-align: top;
}

.percent50 {
  width: 50%;
  .percentCommon();
}

.percent33 {
  width: 33.33%;
  .percentCommon();
}

.percent25 {
  width: 25%;
  .percentCommon();
}


@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;