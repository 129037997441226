.phone-container {
  display: inline-flex;
  align-items: center;
  &-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    margin-left: 4px;
    cursor: pointer;
    border-radius: 2px;
    color: #999;
    font-size: 12px;
  }
}
@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;