@import '~framework/assets/css/config';

.upload {
  :global {
    .el-form-item__content {
      display: inline-block;
      margin-left: 10px;
      margin-top: 10px;
      .el-input {
        width: 300px;
      }
    }
  }
}

.selectFile {
  margin-left: 5px;
  padding: 0 10px;
  // line-height: 38px;
  // background: #3296fa;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  :global {
    .el-form-item__label {
      margin-top: 20px;
      margin-left: -15px;
      vertical-align: top;
    }
  }
}

.errMsg{
  color: 'red';
  margin-left: 10px;
  margin-top: -20px;
  color: red;
}
.errTit {
  margin-bottom: 0;
  position: relative;
}
.errArea {
  position: relative;
  overflow-y: auto;
  max-height: 200px;
  p {
    margin-bottom: 0;
  }
}
.red {
  color: red;
}
.firstLabel {
  float: left;
}
.firstRow {
  position: relative;
  margin-left: 65px;
  display: block;
  color: red;
}
.errRow {
  margin-left: 65px;
  color: red;
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;