@import "~framework/assets/css/config.less";

.contentBox {
  position: relative;
  padding-top: 80px;
  margin-left: @menu-normal-width + @menu-margin-right;
  //background-color: #ffffff;
  transition: margin-left 200ms;
  z-index: 1;
  min-width: 1050px;
  .clearfix();
  &.showThirdLevelMenu {
    margin-left: @menu-normal-width + @third-level-menu-width + @menu-margin-right;
  }
  &.menuCollapsed {
    margin-left: @menu-collapsed-width + @menu-collapsed-margin-right;
  }
  &.showThirdLevelMenu.menuCollapsed {
    margin-left: @menu-collapsed-width + @third-level-menu-width + @menu-margin-right;
  }
  .contentInnerWrap {
    background-color: #ffffff;
    .clearfix();
  }
  .contentInner {
    width: 100%;
    float: left;
    padding: 20px 15px;
  }
}

:global {
  .hide-menu .content-box {
    transition: margin-left 0s;
    width: 1200px;
    margin: 0 auto;
  }
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;