.linkItem {
  margin-right: 10px;
}

.moreArrow {
  font-size: 12px;
  margin-left: 3px;
}

.moreLinkList {
  width: 135px;
}

.operatePopover {
  padding: 0;
  :global {
    .ant-popover-inner-content {
      padding: 0;
    }
    .ant-popover-arrow {
      display: none;
    }
  }
}

.moreLinkItem {
  display: block;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #333333;
  text-align: center;
  &:hover {
    color: #3296fa;
    background-color: #e6f7ff;
  }
  .moreLinkItemInner {
    width: 75px;
    text-align: left;
    display: inline-block;
  }
}
@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;