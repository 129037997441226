:global {
  .cjm-date-picker {
    display: block;
    width: 100%;

    .ant-input {
      height: 36px;
      line-height: 36px;
      border: 1px solid #bfcbd9;
    }
  }

  .el-form-item.is-error {
    .cjm-date-picker {
      .ant-input {
        border: 1px solid #ff4949;
      }
    }
  }
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;