.imageItem {
  position: relative;
  width: 148px;
  height: 148px;
  line-height: 148px;
  font-size: 0;
  border-radius: 6px;
  text-align: center;
  box-sizing: border-box;
  border: 1px dashed #c0ccda;
  display: inline-block;
  vertical-align: top;
  margin: 0 8px 8px 0;
  img {
    max-width: 140px;
    max-height: 140px;
    display: inline-block;
    vertical-align: middle;
  }
  .cover {
    display: none;
  }
  &:hover {
    .cover {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 0;
    }
  }
  .linkIcon {
    display: inline-block;
    width: 20px;
    height: 20px;
    //background-color: #ffffff;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;