.image-preview {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 90px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  &:hover .image-cover {
    transform: scale(1);
  }
  .image-preview-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .image-cover {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .6);
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    transition: all .3s ease-in-out;
    transform: scale(0);
  }
}
.modal-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
:global {
  .ant-modal-body {
    padding: 36px 24px;
  }
}
@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;