.toggleContainer {
  position: absolute;
  left: 25px;
  top: 14px;
  z-index: 100;
  img {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
  .tip {
    position: absolute;
    left: 51px;
    top: 0;
    height: 36px;
    width: 162px;
    line-height: 36px;
    text-indent: 35px;
    background-image: url('../../../../assets/images/dialogue_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #738299;
    font-size: 16px;
  }
}
@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;