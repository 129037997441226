@import "~framework/assets/css/config.less";

@backColor: #f5f5f5;
@fontColor: #555555;
@activeBackColor: #dedede;

.menuHeaderLogo {
  position: relative;
  height: 70px;
  background-color: #FF6A00;
  background-image: url("framework/assets/images/menu-header-logo.png");
  background-repeat: no-repeat;
  background-position: center center;
  .menuCollapsed & {
    background-image: url("framework/assets/images/menu-header-logo-min.png");
  }
}

.menuBoxWrap {
  position: relative;
  float: left;
  width: @menu-normal-width;
  transition: width 200ms;
  .clearfix();
  .baseMenuBox {
    position: absolute;
    left: 0;
    top: 0;
    width: @menu-normal-width;
    height: 100%;
    transition: width 200ms;
    //background-color: #506081;
    background-color: @backColor;
    z-index: 3;
  }
  &.showThirdLevelMenu {
    width: @menu-normal-width + @third-level-menu-width;
  }
  &.menuCollapsed {
    width: @menu-collapsed-width;
    .baseMenuBox {
      width: @menu-collapsed-width;
    }
  }
  &.showThirdLevelMenu.menuCollapsed {
    width: @menu-collapsed-width + @third-level-menu-width;
  }
}

.collapseSwitch {
  height: 70px;
  background-color: #2e74d3;
  cursor: pointer;
  text-align: left;
  .collapseSwitchIcon {
    display: inline-block;
    vertical-align: top;
    font-size: 18px;
    color: #fff;
    margin-top: 24px;
    margin-left: 24px;
  }
  &.isCollapsed {
    .collapseSwitchIcon {
      transform: rotateY(180deg);
      //-webkit-transform: rotateY(130deg); /* Safari 与 Chrome */
    }
  }
}

.menuItem {
  position: relative;
  height: 54px;
  line-height: 54px;
  display: block;
  cursor: pointer;
  overflow: hidden;
  transition: height 200ms;
  .leftBorder {
    position: absolute;
    left: 0;
    top: 0;
    width: 3px;
    height: 54px;
    background-color: #dedede;
    display: none;
  }
  .menuIcon {
    display: inline-block;
    //opacity: 0;
    vertical-align: top;
    color: @fontColor;
    margin-left: 16px;
    font-size: 14px;
    margin-top: 20px;
    .menuCollapsed & {
      font-size: 18px;
      margin-left: 26px;
    }
  }
  .menuName {
    color: @fontColor;
    display: inline-block;
    vertical-align: top;
    margin-left: 8px;
    font-size: 14px;
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .menuCollapsed & {
      display: none;
    }
  }
  .openPage {
    float: right;
    margin-right: 12px;
    color: @fontColor;
    font-size: 15px;
  }
  .arrow {
    float: right;
    margin-right: 12px;
    color: @fontColor;
    font-size: 10px;
    .menuCollapsed & {
      display: none;
    }
  }
  .menuItemHeader {
    background-color: transparent;
    &:hover {
      background-color: @activeBackColor;
      .menuName {
        color: #333333;
        font-weight: bold;
      }
    }
  }
  .menuCollapsed & {
    height: 36px;
    line-height: 36px;
    .leftBorder {
      height: 36px;
    }
    .menuIcon {
      margin-top: 9px;
    }
    &.menuItemActive {
      background-color: transparent;
      font-weight: bold;
    }
    &:hover {
      background-color: @activeBackColor;
    }
  }
  &.menuItemCurrent .menuItemHeader > .leftBorder {
    display: block;
  }
  &.menuItemIncludesCurrent {
    .menuCollapsed & {
      background-color: @activeBackColor;
      .leftBorder {
        display: block;
      }
    }
  }
  &.menuItemCurrent {
    .menuCollapsed & {
      background-color: @activeBackColor;
    }
  }
}

.subMenuItem {
  position: relative;
  background-color: @backColor;
  display: block;
  line-height: 36px;
  height: 36px;
  .menuCollapsed & {
    display: none;
  }
  .subMenuItemName {
    color: @fontColor;
    margin-left: 37px;
  }
  &:hover {
    background-color: @activeBackColor;
  }

  &.subMenuItemCurrent > .leftBorder {
    display: block;
  }
  &.subMenuItemCurrent {
    background-color: @activeBackColor;
  }
}

//
.thirdLevelMenuBox {
  position: absolute;
  left: @menu-normal-width;
  top: 0;
  width: 0;
  height: 100%;
  height: calc(~"100% - 70px");
  background-color: #ffffff;
  margin-top: 70px;
  transition: width 200ms, left 200ms;
  .showThirdLevelMenu & {
    width: @third-level-menu-width;
  }
  .menuCollapsed & {
    left: @menu-collapsed-width;
  }
}

.thirdLevelMenuItem {
  display: block;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  cursor: pointer;
  transition: height 200ms;
  .thirdLevelMenuItemName {
    display: inline-block;
    font-size: 14px;
    color: #333333;
    margin-left: 16px;
  }
  .openPage {
    float: right;
    margin-right: 28px;
    color: #333333;
    font-size: 15px;
  }
  .arrow {
    float: right;
    margin-right: 28px;
    color: #bfbfbf;
    font-size: 10px;
  }
  &:hover {
    background-color: #ffffff;
    .thirdLevelMenuItemName,
    .openPage {
      color: #333333;
    }
  }
  &.thirdLevelMenuItemActive {
    &:hover {
      background-color: #ffffff;
      .thirdLevelMenuItemName {
        color: #333333;
      }
    }
  }
  &.thirdLevelMenuItemCurrent {
    background-color: @activeBackColor;
    .thirdLevelMenuItemName {
      color: #333333;
    }
  }
}

.thirdLevelMenuSwitch {
  position: absolute;
  top: 350px;
  cursor: pointer;
  right: -16px;
  transition: right 200ms;
  z-index: 9;
  .showThirdLevelMenu & {
    right: 0;
  }
}

.fourthLevelMenuItem {
  display: block;
  .fourthLevelMenuItemName {
    margin-left: 30px;
    color: #666666;
  }
  &:hover {
    background-color: #ffffff;
    .fourthLevelMenuItemName {
      color: #3296fa;
    }
  }
  &.fourthLevelMenuItemCurrent {
    background-color: @activeBackColor;
    .fourthLevelMenuItemName {
      color: #3296fa;
    }
  }
}

:global {
  .hide-menu {
    .menu-box-wrap {
      display: none;
    }
  }
  .menu-box-wrap {
    .sc-icon-open-page {
      display: none;
    }
    a:hover .sc-icon-open-page {
      display: block;
    }
  }
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;