@import '~framework/assets/css/config';

@logo-height: 45px;
.header {
  position: absolute;
  left: 0;
  top: 0;
  height: @header-height;
  width: 100%;
  min-width: 1250px;
  background-color: @header-background-color;
  padding-left: @menu-normal-width;
  transition: padding-left 200ms;
  z-index: 2;
  .clearfix();
  &.menuCollapsed {
    padding-left: @menu-collapsed-width + @menu-collapsed-margin-right;
  }
  /*&.showThirdLevelMenu {
    margin-left: @menu-normal-width + @third-level-menu-width;
    border-left: 1px solid #F3F3F3;
  }
  &.menuCollapsed {
    margin-left: @menu-collapsed-width + @menu-collapsed-margin-right;
  }
  &.showThirdLevelMenu.menuCollapsed {
    margin-left: @menu-collapsed-width + @third-level-menu-width;
  }*/
}

:global {
  .hide-menu .global-header {
    transition: margin-left 0s;
    margin-left: 0;
    padding-left: 0;
  }
}

.headerLogo {
  height: @logo-height;
  display: inline-block;
  vertical-align: top;
  margin-top: (@header-height - @logo-height) / 2;
  margin-left: 30px;
}

.textLogo {
  font-size: 0;
  color: #435170;
  img,
  span {
    height: 45px;
    display: inline-block;
    vertical-align: top;
    line-height: 45px;
  }
  img {
    margin-right: 7px;
    display: none;
  }
  span {
    font-size: 30px;
    font-weight: bold;
  }
}

.toolsGroup {
  position: relative;
  float: right;
  margin-right: 30px;
  margin-top: 28px;
  .tool {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    margin-left: 24px;
  }
  .toolIcon {
    @logoSize: 18px;
    color: #9a9a9a;
    display: inline-block;
    vertical-align: top;
    width: @logoSize;
    height: @logoSize;
    font-size: @logoSize;
    line-height: @logoSize;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
    &.fontFix {
      margin-top: -2px;
    }
  }
  .unReadSign {
    position: absolute;
    right: -12px;
    top: -12px;
    padding: 2px 5px;
    background-color: #ff0000;
    border-radius: 3px;
    color: #ffffff;
    line-height: 1;
    font-style: normal;
    font-size: 12px;
    cursor: pointer;
  }
  .userTool {
    width: auto;
    height: 24px;
    margin-top: -3px;
    white-space: nowrap;
    cursor: pointer;
    .userNick {
      display: inline-block;
      font-size: 14px;
      color: #ffffff;
      vertical-align: top;
      margin-left: 8px;
      margin-top: 1px;
    }
    .arrow {
      margin-top: 8px;
    }
  }
  .arrow {
    display: inline-block;
    vertical-align: top;
    font-size: 10px;
    line-height: 10px;
    margin-top: 5px;
    margin-left: 8px;
    color: #ffffff;
  }
}

.userCtrlContent {
  padding-top: 52px;
  min-width: 106px;
  .linkItem {
    cursor: pointer;
    margin-bottom: 15px;
    padding: 0 9px;
    white-space: nowrap;
    font-size: 14px;
    line-height: 14px;
    color: #333333;
    &:last-child {
      margin-bottom: 0;
    }
    i {
      margin-right: 18px;
      vertical-align: top;
    }
  }
}

.popNickPhone {
  position: absolute;
  left: 0;
  top: 8px;
  width: 100%;
  height: 55px;
  background-color: #ffffff;
  border-bottom: 1px solid #dfe3e5;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  .nickIconWord {
    position: absolute;
    left: 14px;
    top: 12px;
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #4b9efb;
    font-style: normal;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    line-height: 32px;
  }
  .popNick {
    position: absolute;
    font-size: 14px;
    line-height: 14px;
    color: #333333;
    left: 55px;
    top: 12px;
    white-space: nowrap;
    width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .popPhone {
    position: absolute;
    font-size: 12px;
    line-height: 12px;
    color: #333333;
    left: 55px;
    top: 32px;
  }
}

.themeCtrlContent {
  width: 196px;
  height: 73px;
  .themeTitleText {
    position: absolute;
    width: 100%;
    height: 42px;
    line-height: 42px;
    left: 0;
    top: 8px;
    border-bottom: 1px solid #e6e6e6;
    font-size: 14px;
    text-indent: 20px;
    color: #333333;
  }
  .themeContent {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 63px;
    label {
      display: inline-block;
      vertical-align: top;
      margin-top: 23px;
      margin-left: 20px;
      font-size: 13px;
      color: #333333;
    }
    ul {
      display: inline-block;
      vertical-align: top;
      padding: 0;
      margin: 0;
      margin-left: 43px;
      margin-top: 23px;
      li {
        list-style-type: none;
        display: inline-block;
        vertical-align: top;
        width: 22px;
        height: 22px;
        border-radius: 5px;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}

.logoutLogo {
  background-image: url('framework/assets/images/logout-logo.png');
}

.toolText {
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
  color: #9a9a9a;
  font-size: 14px;
  line-height: 14px;
  margin-left: 7px;
}

:global {
  .header-popover {
    .ant-popover-inner {
      box-shadow: 0 0 25px #aaaaaa;
    }
  }
}

.noticeCtrlContent {
  width: 290px;
  // height: 330px;
  .messageWarp {
    height: 96px;
    border-bottom: 1px solid #e4e4e4;
    padding: 25px;
    line-height: 21px;
    &:hover {
      background: #eaf7ff;
    }
    div:first-child {
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold;
      font-weight: bold;
      color: rgba(103, 106, 108, 1);
    }
    div:nth-child(2) {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: rgba(103, 106, 108, 1);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    div:nth-child(3) {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: rgba(179, 179, 179, 1);
    }
  }
  .moreMessage {
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-family: MicrosoftYaHei-Bold;
    font-weight: bold;
    color: rgba(103, 106, 108, 1);
    &:hover{
      cursor: pointer;
    }
    img {
      position: relative;
      top: -2px;
      margin-right: 12px;
    }
  }
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;