.crumbBar {
  height: 50px;
  background-color: #ffffff;
  margin-bottom: 10px;
  padding-left: 17px;
  box-sizing: border-box;
}

.crumbLinkItem, .crumbNormalItem {
  display: inline-block;
  vertical-align: top;
  line-height: 20px;
  margin-top: 15px;
  i {
    margin: 0 5px;
    font-style: normal;
    color: #666666;
  }
}

.crumbLinkItem {
  font-size: 14px;
  color: #666666;
  &:focus, &:active {
    text-decoration: none;
  }
}

.crumbNormalItem {
  color: #333333;
  font-weight: bolder;
}

:global {
  .hide-menu .crumb-bar {
    background-color: transparent;
    margin-bottom: 0;
    padding-left: 0;
  }
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;