.icon(@url) {
  display: inline-block;
  background-image: url(@url);
  width: image-width(@url);
  height: image-height(@url);
  background-size: image-width(@url) image-height(@url);
  background-position: center center;
  background-repeat: no-repeat;
}

.icon-import {
  .icon("../../images/import.png");
}

.icon-export {
  .icon("../../images/export.png");
}
