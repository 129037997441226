html,
body,
:global(#root) {
  height: 100%;
}

:global(.ant-layout) {
  min-height: 100%;
}

canvas {
  display: block;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// temp fix for https://github.com/ant-design/ant-design/commit/a1fafb5b727b62cb0be29ce6e9eca8f579d4f8b7
:global {
  .ant-spin-container {
    overflow: visible !important;
  }

  .global-spin {
    width: 100%;
    margin: 40px 0 !important;
  }
  @import './assets/css/global-theme.less';
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;