.imageVerifyInput {
  position: relative;
  display: block;
  width: 378px;
}

.input {
  margin-right: 100px;
}

.image {
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
  height: 100%;
  img {
    position: absolute;
    cursor: pointer;
    width: 90%;
    height: 94%;
    top: 3%;
    right: 0;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 3px;
  }
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;