@import "~framework/assets/css/config";

.listWrap {
}

.header {
  .clearfix();
}

.operateSearchWrap {
  .clearfix();
  padding: 10px 0;
}

.operateWrap {
  float: left;
}

.baseSearchWrap {
  float: right;
}

.advanceSearchWrap {
  padding: 10px 0;
  float: right;
  &.showAdvanced {
    float: none;
  }
}

.hasAdvanceSearch {
  position: relative;
  .operateSearchWrap {
    // position: absolute;
    // left: 0;
    // top: 5px;
    float: left;

    padding: 0;
  }
}

.tableWrap {
}

.pageBarWrap {
  text-align: right;
  padding: 10px 0;
}

.pageShortInfo,
.pageBar {
  display: inline-block;
  vertical-align: top;
}

.pageShortInfo {
  margin-top: 5px;
  margin-right: 5px;
}

:global {
  .cjm-list {
    .ant-input-search {
      float: left;
      text-align: left;
    }
    .toggle-form-link {
      line-height: 1;
      display: inline-block;
      vertical-align: top;
      margin-top: 8px;
    }
    .ant-input-group .ant-input {
      z-index: 0;
    }
    .el-table-column--selection .el-checkbox__label {
      display: none;
    }
  }
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;