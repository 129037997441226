:global {
  .cjm-remote-select {
    width: 100%;
    display: inline-block;

    .ant-select-selection {
      border: 1px solid #bfcbd9;
      height: 36px;
      box-shadow: none;

      .ant-select-selection__rendered {
        line-height: 36px;
      }
    }

    &.ant-select {
      &.cjm-remote-icon-caret {
        .ant-select-arrow {
          right: 0;
          top: 0;
        }
      }

      .el-input__icon.el-icon-caret-top {
        transform: rotate(-180deg);
        width: 35px;
        height: 35px;
        top: 5px;
      }

      &.ant-select-open {
        .el-input__icon.el-icon-caret-top {
          transform: rotate(0);
        }
      }
    }
  }

  .el-form-item.is-error {
    .cjm-remote-select {
      .ant-select-selection {
        border: 1px solid #ff4949;
      }
    }
  }

  .el-form-item__content {
    & > .cjm-remote-select, & > .el-select {
      display: block;
    }
  }
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;