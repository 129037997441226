@import "~framework/assets/css/config";

.checkboxGroup {
  font-size: 0;
}

.checkboxWrap {
  display: inline-block;
  vertical-align: top;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  margin-right: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 0;
  cursor: pointer;
  padding: 0 10px;
  overflow: hidden;
  &.checked {
    border: 1px solid @primary-color;
  }
}

.checkbox {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.label {
  font-size: 14px;
  color: #333333;
  display: inline-block;
  vertical-align: top;
  .checked & {
    color: @primary-color;
  }
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;