.selectWrap{
  height: 36px;
  :global{
    .ant-select-selection__placeholder{
      display: none!important;
    }
    .ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered{
      height: 36px!important;
    }
    .ant-select-auto-complete.ant-select .ant-select-selection__rendered{
      height: 36px!important;
    }
  }
}
@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;