.round(@size) {
  width: @size;
  height: @size;
  border-radius: 50%;
}

.textFontHeight(@size) {
  font-size: @size;
  height: @size;
  line-height: @size;
}

.clearfix() {
  zoom: 1;
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
    visibility: hidden;
    font-size: 0;
    height: 0;
  }
}

